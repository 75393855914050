.cssloadWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    box-pack: center;
    box-align: center;
    align-items: center;
    perspective: 390px;
    height: 300px;
    overflow: hidden;
    min-width: 200px;

}
.cssloadText{
    font-size: 26px;
    /*font-weight: bolder;*/
    background-color: #02baff;
    background: -webkit-linear-gradient(#3498DB, #02baff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.cssloadCssloadSpinner {
    /*height: 200px;*/
    position: relative;
    transform-style: preserve-3d;
    animation: cssload-wobble 3.59s ease-in-out infinite;
}

.cssloadCssloadSpinner:before,
.cssloadCssloadSpinner:after {
    display: block;
    content: ' ';
    border-radius: 50%;
    width: 88px;
    height: 88px;
    clip-path: polygon(50% 0, 7% 26%, 7% 74%, 50% 100%, 93% 74%, 93% 26%, 83% 32%, 83% 69%, 50% 89%, 17% 69%, 17% 32%, 50% 12%, 83% 32%, 93% 26%);
    animation: cssload-spin 1.73s ease-in-out infinite both reverse;
}

.cssloadCssloadSpinner:before {
    background-color: #02baff;
    background: linear-gradient(to right, #3498DB, #02baff);
    position: absolute;
}

.cssloadCssloadSpinner:after {
    background-color: #4c4c4c;
    background: linear-gradient(to right, #768f9d, #4c4c4c);
    animation-delay: -0.86s;
}

@keyframes cssload-wobble {
    0%,
    100% {
        transform: rotateX(8.75deg) rotateY(35deg);
    }
    50% {
        transform: rotateX(35deg) rotateY(-35deg) rotate(180deg);
    }
}

@keyframes cssload-spin {
    0%,
    100% {
        transform: translateZ(49px) rotateX(14deg);
    }
    33% {
        transform: translateZ(-49px) scale(0.4);
    }
    66% {
        transform: translateZ(-49px);
    }
}


@keyframes blink {
    /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
    0% {
        opacity: .2;
    }
    /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
    20% {
        opacity: 1;
    }
    /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
    100% {
        opacity: .2;
    }
}
.saving span:before{
    content:"";
    width: 3px;
    height: 3px;
    position: absolute;
    transform: translate(2px, 24px);
    /*top: 0;*/
    background-color: #02baff;
    background: linear-gradient(to right, #3271ff, #02baff);
    border-radius: 50%;
}
.saving span {
    /*-webkit-languages-fill-color: #4c4c4c;*/
    /**
     * Use the blink animation, which is defined above
     */
    animation-name: blink;
    /**
     * The animation should take 1.4 seconds
     */
    animation-duration: 1.4s;
    /**
     * It will repeat itself forever
     */
    animation-iteration-count: infinite;
    /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
    animation-fill-mode: both;
}

.saving span:nth-child(2) {
    /**
     * Starts the animation of the third dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
    animation-delay: .2s;
}

.saving span:nth-child(3) {
    /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
    animation-delay: .4s;
}
